import IngredientAdditiveEntity, {IHasAdditive} from "@/entities/ingredient-additive-entity";
import AdditiveEntity, {
  AdditiveFunctionGroupType,
  AdditiveType,
  PurposeCategoryLabel
} from "@/entities/additive-entity";
import sumBy from "lodash/sumBy";
import maxBy from "lodash/maxBy";
import uniqBy from "lodash/uniqBy";
import groupBy from "lodash/groupBy";
import cloneDeep from 'lodash/cloneDeep';
import ProductDisplayServiceAllergen from "@/services/product-display-service-allergen";
import AllergyEntity from "@/entities/allergy-entity";

import {IAdditiveOption, IAllergenOption} from "@/entities/product-display-setting";
import {
  AdditivePurposeSynonyms, AdditivePurposeThickeningStabilizerId,
  getAdditivePurposeBySynonymId
} from "@/repositories/master/additive-repository";
import {IngredientNameOption} from "@/services/product-display-service";
import {IRecipeComponent} from "@/entities/interfaces/i-recipe-component";

interface IIngredientAdditiveWithAmount {
  concentrationRatio: number;
  amount: number;
  additive: IngredientAdditiveEntity;
}

interface INormalizedAdditiveItemBase {
  displayName: string;
  concentrationRatio: number;
  amount: number;
  allergens: AllergyEntity[];
  items: INormalizedAdditiveItemBase[];
}
// 物質名(アレルゲン・アレルゲン由来)、一括用途名（アレルゲン・アレルゲン由来）、併記用途名（物質名：アレルゲン・アレルゲン由来、物質名：アレルゲン由来）
interface INormalizedAdditiveItem extends INormalizedAdditiveItemBase {
  type: 'normal'|'ikkatsu'|'heiki';
}
export interface IMergedNormalizedAdditiveItem extends INormalizedAdditiveItemBase {
}

export default class ProductDisplayServiceAdditive {
  public static flattenAdditiveList(product: IRecipeComponent, opt: IngredientNameOption): IIngredientAdditiveWithAmount[] {
    return product.getAllIngredientAdditivesRecursive().flatMap(ia => {
      return {
        concentrationRatio: product.findAmountRatioForIngredientOrderInTheRoot(ia, false) || 0,
        amount: product.findAmountRatioForIngredientOrderInTheRoot(ia, opt.calcWithReducedWeight) || 0,
        additive: ia
      };
    });
  }

  public static getMergedAdditiveItems(
    items: IIngredientAdditiveWithAmount[],
    carryoverIngredientAdditiveIds: number[],
    opt:IAllergenOption&IAdditiveOption,
    debug: boolean = false
  ): IMergedNormalizedAdditiveItem[] {
    let additiveList = ProductDisplayServiceAdditive.excludeCarryover(items, carryoverIngredientAdditiveIds, opt.isAllergenSummarized);

    if (debug) {
      console.log('carryovers', carryoverIngredientAdditiveIds);
      console.log('items', items);
      console.log('additiveList', additiveList);
    }

    additiveList = ProductDisplayServiceAdditive.excludeManufactureAgent(additiveList);

    if (!additiveList.length) return [];

    // 調味料を一つにまとめる
    additiveList = ProductDisplayServiceAdditive.groupAndAppendEtcSeasoning(additiveList);

    // 用途併記の添加物 (ex: 甘味料(サッカリンNa、アセスルファムK)）
    let additivesWithPurposeHeiki = additiveList.filter(a => {
      return !!a.additive.additivePurposeSynonym && a.additive.additivePurposeSynonym.additivePurpose!.category === PurposeCategoryLabel.NEED_HEIKI;
    });
    // 一括表記用途の添加物（ex: イーストフード）
    const additivesWithPurposeIkkatsu = additiveList.filter(a => {
      return !!a.additive.additivePurposeSynonym && a.additive.additivePurposeSynonym.additivePurpose!.category === PurposeCategoryLabel.NEED_IKKATSU;
    });
    // 用途無しまたは用途=製造溶剤の添加物
    const additivesWithoutPurpose:IIngredientAdditiveWithAmount[] = additiveList.filter(a => {
      return !a.additive.additivePurposeSynonym || a.additive.additivePurposeSynonym.additivePurpose!.category === PurposeCategoryLabel.MANUFACTURE_AGENT
    });

    // 着色料を省略する
    if (!opt.isColoringPurposeVisible) {
      const coloringPurposes = additivesWithPurposeHeiki.filter(p => p.additive.additivePurposeSynonym!.synonym === '着色料');
      if (coloringPurposes.every(i => i.additive.displayName.includes('色'))) {
        additivesWithPurposeHeiki = additivesWithPurposeHeiki.filter(p => p.additive.additivePurposeSynonym!.synonym !== '着色料');
        additivesWithoutPurpose.push(...coloringPurposes);
      }
    }

    const normalizedHeiki:INormalizedAdditiveItem[] = ProductDisplayServiceAdditive.normalizePurposeHeiki(additivesWithPurposeHeiki, opt);
    const normalizedIkkatsu:INormalizedAdditiveItem[] = additivesWithPurposeIkkatsu.map(a => {
      return {
        type: 'ikkatsu',
        displayName: a.additive.additivePurposeSynonym!.synonym,
        concentrationRatio: a.concentrationRatio,
        amount: a.amount,
        allergens: a.additive.originAllergens,
        items: []
      };
    });
    const normalizedOther:INormalizedAdditiveItem[] = ProductDisplayServiceAdditive.normalizeNormal(additivesWithoutPurpose, opt);

    // すべてを結合して、再度名前でgroupしてamountをsumして並び替え
    const concat:INormalizedAdditiveItem[] = normalizedHeiki.concat(...normalizedIkkatsu).concat(...normalizedOther);
    const separator = '%%%%';
    const grouped = groupBy(concat, (i: INormalizedAdditiveItem) => {
      // ●一括表記用途 & 種別が用途の添加物 => マージされる [ex: イーストフード、イーストフード => イーストフード]
      // ●併記用途 & 種別が用途の添加物 => マージされない [ex: 酸化防止剤、酸化防止剤（二酸化硫黄）] ※1つめは添加物、2つめは用途（添加物）
      return i.displayName + separator + (i.type === 'heiki' ? 'heiki' : 'not-heiki');
    });
    const merged = Object.keys(grouped).map((key:string) => {
      const items:INormalizedAdditiveItem[] = grouped[key];
      return {
        displayName: key.split(separator)[0],
        concentrationRatio: sumBy(items, ia => ia.concentrationRatio),
        amount: sumBy(items, ia => ia.amount),
        allergens: items.flatMap(i => i.allergens),
        items: items.flatMap(i => i.items)
      };
    });
    return merged.sort((a, b) => (b.amount - a.amount) || (a.displayName.codePointAt(0)! - b.displayName.codePointAt(0)!) );
  }


  public static getAdditiveNames(
    sorted: IMergedNormalizedAdditiveItem[],
    opt:IAllergenOption,
    debug: boolean = false,
  ): string {
    const names = sorted.map((item: IMergedNormalizedAdditiveItem) => {
      const prefix = debug ? `<${item.amount}>` : '';
      if (item.items.length) {
        const innerAdditive = item.items.map(inner => {
          return inner.displayName + ProductDisplayServiceAdditive.getAllergenNames(inner.allergens, true, opt);
        }).join('、');
        return item.displayName + `（${innerAdditive}）${prefix}`;
      } else {
        // 一括(displayName = 用途) or 物質名(displayName = 物質名)
        return item.displayName + ProductDisplayServiceAdditive.getAllergenNames(item.allergens, false, opt) + prefix;
      }
    });

    return names.join('、');
  }

  // 別名を一つの表記にまとめる
  // ex: ビタミンCとアスコルビン酸 => ビタミンCにまとめる
  public static groupByAdditive(additiveList:IIngredientAdditiveWithAmount[]): IIngredientAdditiveWithAmount[] {
    const grouped = groupBy(additiveList, a => a.additive.additiveId);

    return Object.keys(grouped).flatMap((key:string) => {
      const items:IIngredientAdditiveWithAmount[] = grouped[key];
      const largest = items.sort((a,b) => b.amount - a.amount)[0];
      const representative = cloneDeep(largest) as IIngredientAdditiveWithAmount;
      representative.amount = sumBy(items, (ia:IIngredientAdditiveWithAmount) => ia.amount || 0);
      representative.concentrationRatio = sumBy(items, (ia:IIngredientAdditiveWithAmount) => ia.concentrationRatio || 0);
      representative.additive.originAllergens = items.flatMap(a => a.additive.originAllergens);

      return representative;
    });
  }


  // 同種の機能の添加物を使用した場合における簡略名
  // ルール1. 酸 + 酸塩(1~n) => name酸(symbols)
  // ex) 安息香酸 + 安息香酸ナトリウム + 安息香酸カリウム => 安息香酸（Na, K)
  // ルール2. 酸塩 + 酸塩 => name酸塩(symbols)
  // ex) 安息香酸ナトリウム + 安息香酸カリウム => 安息香酸塩（Na, K)
  // ルール3. 化合物 + 化合物 => name化物(symbols)
  // ex) 塩化カルシウム + 塩化カリウム => 塩化物（Ca, K)
  // ※symbol内は多い順
  public static groupByFunction(additiveList:IIngredientAdditiveWithAmount[]): IIngredientAdditiveWithAmount[] {
    const additivesWithFunctionGroup: IIngredientAdditiveWithAmount[] = additiveList.filter(a => a.additive.additive!.hasFunctionGroup);

    const additiveGroupByFunctionName:{[functionGroupName:string]: IIngredientAdditiveWithAmount[]} =
      groupBy(additivesWithFunctionGroup, a => a.additive.additive!.functionGroup!.name);

    const mergedAdditiveList:IIngredientAdditiveWithAmount[] = Object.keys(additiveGroupByFunctionName).flatMap((key:string) => {
      const items:IIngredientAdditiveWithAmount[] = additiveGroupByFunctionName[key];

      // 1種類しか存在しない場合はなにもしない
      if (uniqBy(items, a => a.additive.additive!.id).length === 1) return items;

      const representative = cloneDeep(items[0]) as IIngredientAdditiveWithAmount;
      const amount = sumBy(items, (ia:IIngredientAdditiveWithAmount) => ia.amount || 0);
      const concentrationRatio = sumBy(items, (ia:IIngredientAdditiveWithAmount) => ia.concentrationRatio || 0);
      const symbols = items
        .filter(a => !!a.additive.additive!.functionGroup!.symbol)
        .sort((a, b) => {
          return (b.amount - a.amount) ||
            (a.additive.additive!.functionGroup!.symbol!.codePointAt(0)! - b.additive.additive!.functionGroup!.symbol!.codePointAt(0)!)
        })
        .map(a => a.additive.additive!.functionGroup!.symbol);

      const name = items.some(a =>
        a.additive.additive!.functionGroup!.type === AdditiveFunctionGroupType.Acid ||
        a.additive.additive!.functionGroup!.type === AdditiveFunctionGroupType.Compound
      ) ? key : (key + '塩');
      representative.additive.setDisplayName(name + `（${uniqBy(symbols, a => a).join('、')}）`);
      representative.additive.originAllergens = items.flatMap(a => a.additive.originAllergens);
      representative.amount = amount;
      representative.concentrationRatio = concentrationRatio;

      return representative;
    });

    const additivesWithOutFunctionGroup:IIngredientAdditiveWithAmount[] = additiveList.filter(a => !a.additive.additive!.hasFunctionGroup);
    return mergedAdditiveList.concat(...additivesWithOutFunctionGroup);
  }

  // 用途無しをNormalize
  private static normalizeNormal(items: IIngredientAdditiveWithAmount[], opt:IAdditiveOption): INormalizedAdditiveItem[] {
    let res = ProductDisplayServiceAdditive.groupByAdditive(items);
    if (opt.isSameFunctionAdditiveGrouped) {
      res = ProductDisplayServiceAdditive.groupByFunction(res);
    }

    const grouped = groupBy(res, (i: IIngredientAdditiveWithAmount) => i.additive.displayName);
    const merged = Object.keys(grouped).map((key:string) => {
      const inner:IIngredientAdditiveWithAmount[] = grouped[key];
      return {
        type: 'normal',
        displayName: key,
        concentrationRatio: sumBy(inner, ia => ia.concentrationRatio),
        amount: sumBy(inner, ia => ia.amount),
        allergens: inner.flatMap(i => i.additive.originAllergens),
        items: []
      } as INormalizedAdditiveItem;
    });
    return merged.sort((a, b) => (b.amount - a.amount) || (a.displayName.codePointAt(0)! - b.displayName.codePointAt(0)!) );
  }

  // 用途併記の添加物をNormalize
  private static normalizePurposeHeiki(additives:IIngredientAdditiveWithAmount[], opt:IAllergenOption&IAdditiveOption):INormalizedAdditiveItem[] {
    const groupedByPurposeOfHeiki = groupBy(additives, a => a.additive.additivePurposeSynonym!.synonym);

    return Object.keys(groupedByPurposeOfHeiki).map((purposeSynonym:string) => {
      let samePurposeItems: IIngredientAdditiveWithAmount[] = groupedByPurposeOfHeiki[purposeSynonym];

      // 増粘多糖類をまとめる
      if (opt.isThickenerAdditiveGrouped && isThickeningStabilizer(purposeSynonym)) {
        samePurposeItems = this.groupThickenerPurposeAdditives(samePurposeItems);

        if (
          samePurposeItems.length === 1 &&
          samePurposeItems[0].additive.displayName === '増粘多糖類' &&
          ['糊料', '増粘剤'].includes(purposeSynonym)
        ) {
          return {
            type: 'normal',
            displayName: '増粘多糖類',
            allergens: samePurposeItems.flatMap(i => i.additive.originAllergens),
            concentrationRatio: sumBy(samePurposeItems, ia => ia.concentrationRatio),
            amount: sumBy(samePurposeItems, ia => ia.amount),
            items: []
          };
        }
      }

      const innerItems = this.normalizeNormal(samePurposeItems, opt);

      return {
        type: 'heiki',
        displayName: purposeSynonym,
        allergens: innerItems.flatMap(i => i.allergens),
        concentrationRatio: sumBy(samePurposeItems, ia => ia.concentrationRatio),
        amount: sumBy(samePurposeItems, ia => ia.amount),
        items: innerItems
      };
    });
  }

  private static excludeManufactureAgent(items: IIngredientAdditiveWithAmount[]) {
    return items.filter(ia => {
      return !ia.additive.additive!.isPurposeManufactureAgentType;
    });
  }

  // キャリーオーバーを除外した、添加物一覧を取得
  private static excludeCarryover(items: IIngredientAdditiveWithAmount[], carryoverIngredientAdditiveIds: number[], isAllergenSummarized:boolean) {
    // キャリーオーバーに設定されている添加物の表示を省略
    return items.filter(ia => {
      if (isAllergenSummarized) {
        // アレルゲン一括表記の場合、キャリーオーバーに設定されている添加物の表示を省略
        return !carryoverIngredientAdditiveIds.includes(ia.additive.id!);
      } else {
        // アレルゲン個別表記の場合で、アレルゲン由来の添加物は省略しない
        return ia.additive.originAllergens.length > 0 || (!carryoverIngredientAdditiveIds.includes(ia.additive.id!));
      }
    });
  }

  private static getAllergenNames(originAllergens:AllergyEntity[], isPurposeHeiki:boolean, opt:IAllergenOption) {
    const allergens = opt.isAllergenSummarized ? [] : ProductDisplayServiceAllergen.getAllergenNames(originAllergens, opt.isRepeatedAllergenOmitted, true);
    if (!allergens.length) return '';
    return isPurposeHeiki ? `：${allergens.join('・')}由来` : `（${allergens.join('・')}由来）`;
  }

  // 別名・由来・用途を考慮した添加物名を返す
  public static getAdditiveDisplayName(ia:IHasAdditive, opt:IAllergenOption = {isAllergenSummarized:false, isRepeatedAllergenOmitted: false}): string {
    if (!ia.additiveSynonym) return '';

    if (ia.additivePurposeSynonymId) {
      const additive = ia.additive!;
      const aps = getAdditivePurposeBySynonymId(additive, ia.additivePurposeSynonymId);
      // 用途あり
      if (aps.category === PurposeCategoryLabel.NEED_IKKATSU) {
        // 一括名（別表第７）により表示
        return aps.synonym + this.getAllergenNames(ia.getOriginAllergens(), false, opt);
      } else if (aps.category === PurposeCategoryLabel.NEED_HEIKI) {
        // 用途名併記（別表第６）により表示
        return aps.synonym + `（${ia.additiveSynonym.synonym}${this.getAllergenNames(ia.getOriginAllergens(), true, opt)}）`;
      }
    }

    // 用途無しまたは製造溶剤系
    return ia.additiveSynonym.synonym + this.getAllergenNames(ia.getOriginAllergens(), false, opt);
  };

  // 調味料ロジック
  // ※アミノ酸、核酸、有機酸及び無機酸のうち2種以上を使用する場合は、
  // 表示では、使用量、使用目的等から代表的なものを（ ）内に記載し、その他は「等」として記載します。
  // 例：L-アスパラギン酸ナトリウム（用途=調味料（アミノ酸））と5'-イノシン酸二ナトリウム（用途=調味料（核酸））を使用した場合⇒調味料（アミノ酸等）
  private static groupAndAppendEtcSeasoning(additiveList:IIngredientAdditiveWithAmount[]): IIngredientAdditiveWithAmount[] {

    // 調味料用途の添加物を取り出す
    const isPurposeSeasoning = (a:IIngredientAdditiveWithAmount) => {
      return a.additive.additivePurposeSynonym && a.additive.additivePurposeSynonym.additivePurpose!.isSeasoning;
    };
    const additivesForSeasoning:IIngredientAdditiveWithAmount[] = additiveList.filter(isPurposeSeasoning);
    if (additivesForSeasoning.length === 0) return additiveList; // ※用途名添加物が複数あった場合は名前でマージされるから無視でOK

    // 代表的な用途（一番量が多い用途を）を選出
    // 調味料は一括表記なので、amount, allergen以外はなんでも良い(あとでマージするときにそこしか見られない）ので、最初のやつでOK
    const additivesForSeasoningGrouped = groupBy(additivesForSeasoning, a => a.additive.additive!.seasoningType);
    const additivesForSeasoningSummed = Object.keys(additivesForSeasoningGrouped).map(key => {
      const items:IIngredientAdditiveWithAmount[] = additivesForSeasoningGrouped[key];
      return {
        seasoningType: Number(key),
        amountSum: sumBy(items, (i:IIngredientAdditiveWithAmount) => i.amount || 0)
      };
    });
    const representativeSeasoningType = maxBy(additivesForSeasoningSummed, item => item.amountSum)!.seasoningType;
    const representative = cloneDeep(additivesForSeasoning.find(a => a.additive.additive!.seasoningType === representativeSeasoningType)!);
    const representativeSeasoningTypeName = representative.additive.additivePurposeSynonym!.getLabelName(representative.additive.additive!);

    // 物質名=調味料が選択されている、かつ「等」が含まれる場合(ex: 物質名=調味料（アミノ酸等））、
    // かつrepresentativeの用途名と同じ場合はマージする（以下、等付き用途名同名添加物とよぶ）
    // 以下例(それぞれ最終調味料がrepresentative）
    // 例1: 調味料（アミノ酸等）、調味料（アミノ酸）=> 調味料（アミノ酸等）
    // 例2: 調味料（アミノ酸等）、調味料（アミノ酸等）、調味料（アミノ酸）=> 調味料（アミノ酸等）
    // （以下対象外ケース）
    // 例3: 調味料（核酸等）、調味料（アミノ酸）=> 調味料（核酸等）、調味料（アミノ酸） ※これは対応しない(等の内訳がわからないため）
    // 例4: 調味料（核酸）、調味料（アミノ酸）=> 調味料（アミノ酸等）または調味料（核酸等）
    // ※物質名=調味料（核酸）の用途に「調味料（核酸）」が選択されているため、additivesForSeasoningに含まれる。 ※調味料（アミノ酸）、調味料（アミノ酸）の場合も同様
    const isAdditiveNameSameAsRepresentative = (a:IIngredientAdditiveWithAmount) => {
      return representative.additive.additive!.id !== a.additive.additive!.id &&
        representativeSeasoningTypeName.replace(/([\)）])?$/, '等$1') ===  a.additive.additive!.name;
    };
    const containsAdditiveNameSameAsRepresentative = !!additiveList.find(isAdditiveNameSameAsRepresentative);

    // ※ 等付き用途名同名添加物（例: 調味料（アミノ酸等））が存在する場合、
    // representativeに「等」がついてさえいれば、あとで同名としてマージされる
    // 等付き用途名同名添加物が複数ある場合も同じロジックでマージされる
    // なので、additivesForSeasoningUniq.length==1のときも「等」を付けてあげるようにする
    const additivesForSeasoningUniqCount = additivesForSeasoningSummed.length;
    if (additivesForSeasoningUniqCount >= 2 || containsAdditiveNameSameAsRepresentative) {
      representative.additive.additivePurposeSynonym!.synonym = representativeSeasoningTypeName.replace(/([\)）])?$/, '等$1');
    } else {
      representative.additive.additivePurposeSynonym!.synonym = representativeSeasoningTypeName;
    }
    representative.amount = sumBy(additivesForSeasoning, (ia:IIngredientAdditiveWithAmount) => ia.amount || 0);
    representative.concentrationRatio = sumBy(additivesForSeasoning, (ia:IIngredientAdditiveWithAmount) => ia.concentrationRatio || 0);
    representative.additive.originAllergens = additivesForSeasoning.flatMap(a => a.additive.originAllergens);

    return additiveList.filter(a => !isPurposeSeasoning(a)).concat(representative);
  }

  // 増粘多糖類ロジック
  // https://foodog.jp/know/food_additive.html

  // # ルール
  // ● 対象の添加物が2種以上なら増粘多糖類とまとめることができる
  //  ・安定剤（キサンタンガム、カラギナン）=> 安定剤（増粘多糖類）
  //  ・ゲル化剤（加工デンプン、カラギナン、タマリンドシードガム） => ゲル化剤（加工デンプン、増粘多糖類）
  // ●用途の増粘剤・糊料は省略できる
  // ・増粘剤（キサンタンガム、グァーガム）=> 増粘剤（増粘多糖類） => 増粘多糖類
  // ●ただし対象外の添加物がその用途に含まれる場合は省略できない。
  // ・増粘剤（キサンタンガム、グァーガム、加工デンプン） => 増粘剤（増粘多糖類、加工デンプン）
  //
  // # フロー
  // 1. 増粘安定剤の「既存添加物・一般飲食物添加物（のうち、主な用途に増粘安定剤があるもの）」を、用途別名毎に「増粘多糖類」とまとめる
  // 2. 増粘剤・糊料の用途内が「増粘多糖類」のみの場合、用途を省略する
  //
  // 例:「安定剤（キサンタンガム、カラギナン）、増粘剤（キサンタンガム、グァーガム）、ゲル化剤（加工デンプン、カラギナン、タマリンドシードガム）、糊料（カラギナン）」
  // => 「安定剤（増粘多糖類）、増粘多糖類、ゲル化剤（加工デンプン、増粘多糖類）、糊料（カラギナン）」
  private static groupThickenerPurposeAdditives(additives:IIngredientAdditiveWithAmount[]): IIngredientAdditiveWithAmount[] {
    const targetAdditives = additives.filter(a => a.additive.additive!.canGroupAsPolysaccharideThickener);
    const targetAdditivesUniq = uniqBy(targetAdditives, (a:IIngredientAdditiveWithAmount) => a.additive.additiveId);

    if (targetAdditivesUniq.length < 2) {
      // 加工デンプン、タマリンドシードガム => 加工デンプン、タマリンドシードガム
      return additives;
    }

    // => 加工デンプン、増粘多糖類
    const representative = cloneDeep(targetAdditives[0]) as IIngredientAdditiveWithAmount;
    representative.additive.setDisplayName('増粘多糖類');
    representative.additive.originAllergens = targetAdditives.flatMap(a => a.additive.originAllergens);
    representative.amount = sumBy(targetAdditives, (ia:IIngredientAdditiveWithAmount) => ia.amount || 0);
    representative.concentrationRatio = sumBy(targetAdditives, (ia:IIngredientAdditiveWithAmount) => ia.concentrationRatio || 0);

    const targetAdditiveIds = targetAdditives.map(ta => ta.additive.additive!.id);
    const notTargetAdditives = additives.filter(a =>!targetAdditiveIds.includes(a.additive.additive!.id))

    return notTargetAdditives.concat([representative]);
  }
}

// 安定剤,増粘剤,ゲル化剤,糊料
export function isThickeningStabilizer(purposeSynonym: string): boolean {
  return AdditivePurposeSynonyms
    .filter(s => s.additivePurposeId === AdditivePurposeThickeningStabilizerId)
    .map(s => s.synonym)
    .includes(purposeSynonym);
}
