import RepositoryBase from "@/repositories/repository-base";
import AdditiveEntity, {
  AdditivePurposeSynonymEntity,
  AdditiveSynonymEntity,
  AdditiveSynonymType,
  AdditiveType,
  PurposeCategoryLabel,
} from "@/entities/additive-entity";
import StringUtils from '@/utils/string-utils';
import CustomCache from "@/utils/custom-cache";
import {i18n} from "@/bootstraps/locale";

export type TAdditiveSynonymListItem = {
  synonymId:number,
  additiveId:number,
  label:string,
  searchKey: string,
  additiveType: AdditiveType,
  synonymType: AdditiveSynonymType,
  synonym: string,
};

const cacheOfListForSpec = new CustomCache<TAdditiveSynonymListItem[]>();
const cacheOfList = new CustomCache<TAdditiveSynonymListItem[]>();
const cacheOfAgentList = new CustomCache<TAdditiveSynonymListItem[]>();

export default class AdditiveRepository extends RepositoryBase<AdditiveEntity> {
  protected endpoint = "additives";
  protected ctor:new(data) => AdditiveEntity = AdditiveEntity;

  public async listForSpec(withCache = true): Promise<TAdditiveSynonymListItem[]> {
    if (withCache) {
      if (cacheOfListForSpec.isCacheValid) return cacheOfListForSpec.value!;
    }
    const res = await this.get(this.endpoint + '/list-for-spec');
    const data = res.data;
    data.forEach(d => {
      d.searchKey = StringUtils.toHankakuKanaLower(d.label) + '製剤';
      d.synonym = StringUtils.toHankakuKanaLower(d.synonym);
    });
    cacheOfListForSpec.set(data, 3600);
    return cacheOfListForSpec.value!;
  }
  public async listForLabel(withCache = true): Promise<TAdditiveSynonymListItem[]> {
    if (withCache) {
      if (cacheOfList.isCacheValid) return cacheOfList.value!;
    }

    const res = await this.get(this.endpoint + '/list');
    const data = res.data;

    data.forEach(d => {
      d.searchKey = StringUtils.toHankakuKanaLower(d.label) + '製剤';
      d.synonym = StringUtils.toHankakuKanaLower(d.synonym);
    });

    cacheOfList.set(data, 3600);

    return cacheOfList.value!;
  }
  public async listManufactureAgentCategory(): Promise<TAdditiveSynonymListItem[]> {
    if (cacheOfAgentList.isCacheValid) return cacheOfAgentList.value!;

    const list = await this.listForLabel();
    const materials = list.filter(r => r.synonymType === AdditiveSynonymType.Material);
    const targetPurposes = [AdditiveType.PurposeHeiki, AdditiveType.PurposeIkkatsu];
    const purposes = materials.filter(r => targetPurposes.includes(r.additiveType));
    const nonPurposes = materials.filter(r => !targetPurposes.includes(r.additiveType));
    const manufactureAgents = (await this.get(this.endpoint + '/list-manufacture-agent')).data;

    const merged = purposes
      .concat(...manufactureAgents)
      .concat(...nonPurposes);

    merged.forEach(d => {
      // : E1412製剤
      d.label = d.label + i18n.t('common.append', [i18n.t('製剤')]);
      d.searchKey = StringUtils.toHankakuKanaLower(d.label) + '製剤';
    });

    cacheOfAgentList.set(merged, 3600);

    return cacheOfAgentList.value!;
  }

  public async find(id:number): Promise<AdditiveEntity> {
    return await this.findById(id);
  }
  public async findSynonym(synonymId:number): Promise<AdditiveSynonymEntity> {
    const res = await this.get('additive_synonyms/' + synonymId);
    return new AdditiveSynonymEntity(res.data);
  }
}


export const AdditivePurposeColoring = 1;
export const AdditivePurposeThickeningStabilizerId = 3;
export const AdditivePurposes = [
  { id: AdditivePurposeColoring, name: '着色料', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: 2, name: '甘味料', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: AdditivePurposeThickeningStabilizerId, name: '増粘安定剤', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: 4, name: '酸化防止剤', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: 5, name: '保存料', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: 6, name: '発色剤', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: 7, name: '防かび剤', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: 8, name: '漂白剤', category: PurposeCategoryLabel.NEED_HEIKI, isSeasoning: false },
  { id: 9, name: '香料', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 10, name: '苦味料', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 11, name: '調味料', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: true },
  { id: 12, name: '酵素', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 13, name: '酸味料', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 14, name: 'ガムベース', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 15, name: '乳化剤', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 16, name: 'イーストフード', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 17, name: '膨脹剤', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 18, name: '豆腐用凝固剤', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 19, name: '軟化剤', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 20, name: '水素イオン濃度調整剤', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 21, name: 'かんすい', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 22, name: '光沢剤', category: PurposeCategoryLabel.NEED_IKKATSU, isSeasoning: false },
  { id: 51, name: '製造用剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 52, name: '栄養強化剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 53, name: '保湿剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 54, name: '品質保持剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 55, name: '被膜剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 56, name: '過酢酸製剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 57, name: 'ろ過助剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 58, name: '消泡剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 59, name: '殺菌料', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 60, name: '結着補強剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 61, name: '日持向上剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 62, name: '抽出溶剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 63, name: '固結防止剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
  { id: 64, name: '品質改良剤', category: PurposeCategoryLabel.MANUFACTURE_AGENT, isSeasoning: false },
];

export const AdditivePurposeSynonyms = [
  { id: 1, additivePurposeId: 2, synonym: '甘味料' },
  { id: 4, additivePurposeId: 1, synonym: '着色料' },
  { id: 6, additivePurposeId: 5, synonym: '保存料' },
  { id: 8, additivePurposeId: 3, synonym: '増粘剤' },
  { id: 9, additivePurposeId: 3, synonym: '安定剤' },
  { id: 10, additivePurposeId: 3, synonym: 'ゲル化剤' },
  { id: 11, additivePurposeId: 3, synonym: '糊料' },
  { id: 12, additivePurposeId: 4, synonym: '酸化防止剤' },
  { id: 13, additivePurposeId: 6, synonym: '発色剤' },
  { id: 14, additivePurposeId: 8, synonym: '漂白剤' },
  { id: 15, additivePurposeId: 7, synonym: '防かび剤' },
  { id: 16, additivePurposeId: 7, synonym: '防ばい剤' },
  { id: 17, additivePurposeId: 16, synonym: 'イーストフード' },
  { id: 18, additivePurposeId: 14, synonym: 'ガムベース' },
  { id: 19, additivePurposeId: 21, synonym: 'かんすい' },
  { id: 20, additivePurposeId: 10, synonym: '苦味料' },
  { id: 21, additivePurposeId: 12, synonym: '酵素' },
  { id: 22, additivePurposeId: 22, synonym: '光沢剤' },
  { id: 23, additivePurposeId: 9, synonym: '香料' },
  { id: 25, additivePurposeId: 13, synonym: '酸味料' },
  { id: 26, additivePurposeId: 19, synonym: '軟化剤' },
  { id: 31, additivePurposeId: 18, synonym: '豆腐用凝固剤' },
  { id: 32, additivePurposeId: 18, synonym: '凝固剤' },
  { id: 33, additivePurposeId: 15, synonym: '乳化剤' },
  { id: 34, additivePurposeId: 20, synonym: '水素イオン濃度調整剤' },
  { id: 35, additivePurposeId: 20, synonym: 'pH調整剤' },
  { id: 36, additivePurposeId: 17, synonym: '膨脹剤' },
  { id: 37, additivePurposeId: 17, synonym: 'ベーキングパウダー' },
  { id: 38, additivePurposeId: 17, synonym: 'ふくらし粉' },
  { id: 39, additivePurposeId: 11, synonym: '調味料' },
  { id: 51, additivePurposeId: 51, synonym: '製造用剤' },
  { id: 52, additivePurposeId: 52, synonym: '栄養強化剤' },
  { id: 53, additivePurposeId: 52, synonym: '強化剤' },
  { id: 54, additivePurposeId: 53, synonym: '保湿剤' },
  { id: 55, additivePurposeId: 54, synonym: '品質保持剤' },
  { id: 56, additivePurposeId: 55, synonym: '被膜剤' },
  { id: 57, additivePurposeId: 56, synonym: '過酢酸製剤' },
  { id: 58, additivePurposeId: 57, synonym: 'ろ過助剤' },
  { id: 59, additivePurposeId: 58, synonym: '消泡剤' },
  { id: 60, additivePurposeId: 59, synonym: '殺菌料' },
  { id: 61, additivePurposeId: 60, synonym: '結着補強剤' },
  { id: 62, additivePurposeId: 61, synonym: '日持向上剤' },
  { id: 63, additivePurposeId: 62, synonym: '抽出溶剤' },
  { id: 64, additivePurposeId: 63, synonym: '固結防止剤' },
  { id: 65, additivePurposeId: 64, synonym: '品質改良剤' },
];

type TAdditivePurposeSynonym = {
  id: number,
  synonym: string,
  category: PurposeCategoryLabel,
  isSeasoning: boolean,
}
export function getAdditivePurposeBySynonymId(additive:AdditiveEntity, synonymId:number): TAdditivePurposeSynonym {
  const s = AdditivePurposeSynonyms.find(aps => aps.id === synonymId)!;
  const ap = AdditivePurposes.find(ap => ap.id === s.additivePurposeId)!;
  return {
    id: s.id,
    synonym: AdditivePurposeSynonymEntity.getLabelNameStatic(s.synonym, ap.isSeasoning, additive),
    category: ap.category,
    isSeasoning: ap.isSeasoning,
  }
}
